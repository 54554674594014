.ExploreMailCard .card-container {
    display: flex;
    justify-content: space-between;
}

.ExploreMailCard .contract-card-dropdown-container {
    all: unset !important;
    z-index: 90 !important;
}

.ExploreMailCard .contract-card-dropdown {
    all: unset !important;
    cursor: pointer !important;
    padding-left: 5px !important;
    padding-right: 5px !important;
}

.ExploreMailCard .contract-card-dropdown::after {
    all: unset !important;
}

.ExploreMailCard .contract-card-dropdown-link {
    color: #4F4F4F;
}

.ExploreMailCard .contract-card-dropdown-item {
    padding: 8px 16px;
    cursor: pointer;
}

.ExploreMailCard .contract-card-dropdown-item:hover {
    background-color: #eee;
}

.ExploreMailCard .delete-dropdown-item {
    color: #dc4c64;
}