.ExploreCard {
    display: flex;
    flex-direction: column;

    height: 100%;
    width: 100%;
}

.ExploreCard .painting-container {
    display: flex;
    position: relative;
    width: 100%;
    aspect-ratio: 1;
    max-width: 400px;
}

.ExploreCard .painting-image {
    max-width: 400px;
}

.ExploreCard .percent100 {
    width: 100%;
}

.ExploreCard .percent90 {
    width: 100%;
}

.ExploreCard .percent85 {
    width: 100%;
}

.ExploreCard .percent80 {
    width: 100%;
}

.ExploreCard .text-container {
    max-width: 400px;
    width: 100%;
    margin-top: 10px;
}

.ExploreCard .card-header-text {
    font-size: 14px;
    font-weight: 900;
    color: var(--first-color);
}

.ExploreCard .card-cursive-text {
    font-size: 14px;
    font-weight: 300;
    color: #000000;
    font-style: italic;
}

.ExploreCard .card-normal-text {
    font-size: 14px;
    font-weight: 300;
    color: #000000;
}

.ExploreCard .text-wrapper {
    margin-bottom: 3px;
}

.ExploreCard .contract-card-dropdown-container {
    /* all: unset !important; */
    z-index: 90 !important;
    position: absolute;
    top: 0;
    right: 0;
}

.ExploreCard .contract-card-dropdown {
    all: unset !important;
    cursor: pointer !important;
    padding-left: 5px !important;
    padding-right: 5px !important;
}

.ExploreCard .contract-card-dropdown::after {
    all: unset !important;
}

.ExploreCard .contract-card-dropdown-link {
    color: #4F4F4F;
}

.ExploreCard .contract-card-dropdown-item {
    padding: 8px 16px;
    cursor: pointer;
}

.ExploreCard .contract-card-dropdown-item:hover {
    background-color: #eee;
}

.ExploreCard .delete-dropdown-item {
    color: #dc4c64;
}

@media (max-width: 650px) {
    .ExploreCard {
        align-items: center;
    }
}