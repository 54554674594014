.ExploreMails .sort-container {
    margin-top: 20px;
    margin-bottom: 40px;
}

.ExploreMails .add-container {
    display: flex;
    justify-content: flex-end;
}

.ExploreMails .mail-card {
    margin-bottom: 15px;
}

@media (max-width: 767px) {
    .ExploreMails .clear-container {
        margin-bottom: 15px;
    }

    .ExploreMails .add-container {
        justify-content: flex-start;
    }
}



.ExploreMails .paging-input {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 50px;
}

.ExploreMails .no-contracts-container {
    display: flex;
    align-items: center;
    justify-content: center;
    color: gray;
}