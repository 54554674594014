.Login {
    padding-top: 70px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Login h1 {
    margin-bottom: 40px;
}

.Login .input-element {
    padding-bottom: 20px;
    width: 400px;
}

@media (max-width: 767px) {
    .Login .input-element {
        width: 100%;
    }
}