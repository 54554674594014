@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;500;600;700;900&display=swap");

body {
  margin: 0;
  font-family: var(--body-font) !important;
  font-size: var(--normal-font-size);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --first-color: #afafaf;
  --second-color: #797979;
  --green-color: #abc04d;
  --body-font: 'Lato', sans-serif;
  --normal-font-size: 1rem;
}