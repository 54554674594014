.NewsletterUserInfo .info-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 100px;
}

.NewsletterUserInfo .count-number {
    font-size: 4rem;
    font-weight: 700;
}

.NewsletterUserInfo .subscriber-text {
    font-size: 1.5rem;
}