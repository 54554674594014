.App {
  padding: 0 1rem;
}

.App .AppContent {
  padding-top: 20px;
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.App .AppContentInner {
  max-width: 1200px;
  width: 100%;
}

.App .custom-row {
  display: flex;
  flex-wrap: wrap;
}

.App .custom-col {
  width: 33.33333%;
  padding: 0 50px;
  margin-bottom: 50px;
}

@media (max-width: 1000px) {
  .App .custom-col {
    padding: 0 30px;
  }
}

@media (max-width: 800px) {
  .App .custom-col {
    padding: 0 15px;
  }
}

@media (max-width: 650px) {
  .App .custom-row {
    flex-direction: column;
  }

  .App .custom-col {
    width: 100%;
    padding: 0 15px;
  }
}