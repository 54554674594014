.Explore .sort-container {
    margin-top: 20px;
    margin-bottom: 40px;
}

.Explore .checkbox-container {
    display: flex;
    align-items: center;
}

.Explore .CustomCheckbox {
    padding-bottom: 0px;
}

.Explore .upload-contract-hint-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

@media (max-width: 767px) {
    .Explore .currency-dropdown {
        margin-bottom: 20px;
    }

    .Explore .CustomCheckbox {
        margin-bottom: 15px;
    }

    .Explore .clear-container {
        margin-bottom: 15px;
    }

    .Explore .upload-contract-hint-container {
        flex-direction: column;
    }

    .Explore .upload-contract-hint-text {
        margin-bottom: 20px;
    }
}



.Explore .paging-input {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 50px;
}

.Explore .single-contract-card {
    margin-bottom: 20px;
    transition: 0.3s;
    cursor: pointer;
}

.Explore .explore-card-col:hover {
    z-index: 100;
}

.Explore .single-contract-card:hover {
    transform: scale(1.02, 1.02);
}

.Explore .no-contracts-container {
    display: flex;
    align-items: center;
    justify-content: center;
    color: gray;
}

.Explore .explore-upload-hint {
    margin-top: 20px;
}