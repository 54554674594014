.EditPaintingContent {
    display: flex;
    justify-content: center;
}

.EditPaintingContent .EditPaintingContentInner {
    width: 100%;
    max-width: 500px;
}

.EditPaintingContent .header-container {
    font-size: 1.4rem;
    font-weight: 700;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 30px;
}

.EditPaintingContent .input-argument {
    padding-bottom: 20px;
}

.EditPaintingContent .button-container {
    display: flex;
    justify-content: center;
    margin-top: 30px;
}

.EditPaintingContent .image-error-container {
    display: flex;
    justify-content: center;
    font-size: 0.9rem;
    color: #DC4B64;
    margin-bottom: 20px;
}