.Navigation {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 40px;
    text-align: center;
}

.Navigation .nav-title {
    font-weight: 300;
    font-size: 44px;
    letter-spacing: 10px;
    text-transform: uppercase;
    color: #4f4f4f;
}

.Navigation .nav-title-art {
    font-weight: 900;
    color: var(--first-color);
}

@media (max-width: 767px) {
    .Navigation .nav-title {
        font-size: 20px;
    }
}