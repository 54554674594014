.Home .upper-card {
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
}

.Home .grey-icon-background {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background-color: #ECEFF1;
    color: #000000;
}

.Home .card-icon {
    width: 30px;
    height: auto;
}

.Home .lower-card {
    display: flex;
    justify-content: center;
    color: #4f4f4f;
    transition: 0.3s;
}

.Home .link-card:hover {
    background-color: rgba(0, 0, 0, 0.01);
}

.Home .margin-bottom-15 {
    margin-bottom: 15px;
}

@media (max-width: 767px) {
    .Home .top-col {
        margin-bottom: 15px;
    }
}