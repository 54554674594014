.SimpleLoadingScreen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    display: none;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0);
    z-index: 1000;
    transition: 0.3;
}

.show-creation-loading {
    display: flex;
    opacity: 1;
}