.Paging .paging-item {
    cursor: pointer;
}

.Paging .disabled-paging-item {
    cursor: not-allowed;
}

.Paging .active-page {
    color: var(--green-color);
}

.Paging .page-disabled {
    color: gray;
}