.ImageSelector {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.ImageSelector .uploader {
    height: 300px;
    width: 300px;
}

.ImageSelector .file-upload-preview-details {
    display: flex;
    align-items: center;
}

.ImageSelector .file-uplod-preview-details-inner {
    width: 250px;
    word-wrap: break-word;
    margin-top: 40px;
}

.ImageSelector .file-upload-remove-file-btn {
    position: absolute;
    right: unset;
    top: 70px;
}

.ImageSelector .wrong-file-alert {
    margin-top: 20px;
}

@media (max-width: 767px) {
    .ImageSelector .uploader {
        height: 250px;
        width: 250px;
    }
}